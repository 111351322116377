import { Footer, Header } from '@/components'
import { getDeviceId, LOGGED_IN_COOKIE_NAME } from '@/utils/cookie'
import { useLink, useLocale, useTranslation } from '@/utils/hook'
import Head from 'next/head'
import { ReactNode, useEffect, useState } from 'react'
import { HelpFloatButton } from '@/components/home/HelpFloatButton'
import { GoogleAnalytics } from '@next/third-parties/google'
import { GoogleTagManager } from '@next/third-parties/google'
import Script from 'next/script'

interface ContainerProps {
  title?: string
  description?: string
  url?: string
  image?: string
  ogType?: string
  customHeader?: ReactNode
  headerBackground?: string
  navLinkColor?: string
  schema?: any[]
  children: ReactNode
}

export function Container({
  title: rawTitle,
  description: rawDesc,
  url,
  image: rawImage,
  ogType = 'website',
  customHeader,
  headerBackground,
  schema: rawSchema = [],
  children
}: ContainerProps): JSX.Element {
  const t = useTranslation()
  const locale = useLocale()

  const title = rawTitle || t('metadata.home.title')
  const description = rawDesc || t('metadata.home.description')
  const homeUrl = useLink('/')
  const publicUrl = useLink(url || '/')
  const image =
    rawImage || `${process.env.NEXT_PUBLIC_WEBSITE_URI}/static/og.png`

  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': `${homeUrl}/#organization`,
        name: t('metadata.home.title'),
        url: homeUrl,
        sameAs: [
          'https://x.com/HeyformHQ',
          'https://www.facebook.com/HeyformHQ',
          'https://www.linkedin.com/company/heyform/'
        ],
        logo: {
          '@type': 'ImageObject',
          '@id': `${homeUrl}/#logo`,
          url: `${homeUrl}/static/logo.svg`,
          caption: t('metadata.home.title')
        },
        image: {
          '@id': `${homeUrl}/#logo`
        }
      },
      {
        '@type': 'WebSite',
        '@id': `${homeUrl}/#website`,
        url: homeUrl,
        name: t('metadata.home.title'),
        description: t('metadata.home.description'),
        inLanguage: locale
      },
      ...rawSchema
    ]
  }

  // Set the device ID cookie for Google one tap login
  const [deviceId, setDeviceId] = useState<string>()

  useEffect(() => {
    setDeviceId(getDeviceId())
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta httpEquiv="x-dns-prefetch-control" content="on" />
        <link rel="dns-prefetch" href="https://cdnjs.cloudflare.com" />
        <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch" href="https://accounts.google.com" />
        <link type="image/x-icon" href="/static/favicon.png" rel="icon" />
        <link type="image/x-icon" href="/static/favicon.png" rel="bookmark" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/apple-touch-icon.png"
        />
        <link rel="canonical" href={publicUrl} />
        <meta content="width=device-width,initial-scale=1" name="viewport" />
        <meta name="theme-color" content={headerBackground || '#fff'} />
        <meta content="yes" name="mobile-web-app-capable" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="HeyForm" name="application-name" />
        <meta content="white" name="apple-mobile-web-app-status-bar-style" />
        <meta content="HeyForm" name="apple-mobile-web-app-title" />
        <meta content="telephone=no,email=no" name="format-detection" />
        <meta property="og:type" content={ogType} />
        <meta property="og:locale" content="en" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={publicUrl} />
        <meta property="og:site_name" content="HeyForm" />
        <meta property="og:description" content={description} />
        {image && <meta property="og:image" content={image} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="twitter:image" content={image} />}
        <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema)
          }}
        />
        <Script
          id="twitter-pixel"
          dangerouslySetInnerHTML={{
            __html: `
           !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o9cp2');
            twq('track','PageView');`
          }}
        />
        <Script
          dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "jt8zd85vbp");`
          }}
        />
      </Head>
      {customHeader || <Header />}
      {children}
      <Footer />
      <HelpFloatButton />
      <Script src="https://cdn.trackdesk.com/tracking.js" />
      <Script
        id="trackdesk-init"
        dangerouslySetInnerHTML={{
          __html: `
          (function(t, d, k) {
            (t[k] = t[k] || []).push(d);
            t[d] = t[d] || t[k].f || function() {
              (t[d].q = t[d].q || []).push(arguments);
            };
          })(window, "trackdesk", "TrackdeskObject");

          trackdesk("heyform", "click");
        `
        }}
      />
      <div
        id="g_id_onload"
        data-client_id={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        data-auto_select="false"
        data-login_uri={process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_CALLBACK}
        data-state={deviceId}
        data-skip_prompt_cookie={LOGGED_IN_COOKIE_NAME}
      />
      <Script src="https://accounts.google.com/gsi/client" />
      <Script
        defer
        data-domain="heyform.net"
        src="https://analytics.heyform.net/js/script.js"
      />
      <GoogleAnalytics gaId="G-NGB9WX5Z4X" />
      <GoogleTagManager gtmId="GTM-WM5XM8GP" />
    </>
  )
}
