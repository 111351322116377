import NextImage from 'next/image'

interface ImageProps extends Record<string, unknown> {
  src?: string
  width?: number
  height?: number
  alt?: string
}

export const Image = ({
  className,
  src,
  width,
  height,
  alt
}: ImageProps): JSX.Element => {
  return (
    <>
      {src && (
        <NextImage
          className={className as string}
          src={src}
          width={width}
          height={height}
          alt={alt}
          loading="lazy"
        />
      )}
    </>
  )
}
