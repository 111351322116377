import { ComponentProps } from '@/components/ui'
import styled from 'styled-components'
import { dayjs } from '@heyform-inc/utils'

interface PostProps extends ComponentProps {
  post: PostModel
  showMeta?: boolean
}

interface PostContentProps extends ComponentProps {
  html: string
}

export function PostContent({
  html,
  ...restProps
}: PostContentProps): JSX.Element {
  return (
    <Content
      {...restProps}
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  )
}

export function Post({ post, showMeta = true }: PostProps): JSX.Element {
  return (
    <Container>
      <Category>{post.primary_tag.name}</Category>
      <Title>{post.title}</Title>
      <Excerpt>{post.custom_excerpt}</Excerpt>
      {showMeta && (
        <Meta>
          <div className="avatar-container">
            <img
              className="avatar"
              src={post.primary_author.profile_image}
              alt="profile_image"
            />
          </div>
          <div className="details">
            <div className="profile-name">{post.primary_author.name}</div>
            <Date>{dayjs(post.published_at).format('MMM D, YYYY')}</Date>
          </div>
        </Meta>
      )}
      <ImageWrapper>
        <img src={post.feature_image} alt="image" />
      </ImageWrapper>
      <PostContent html={post.html} />
    </Container>
  )
}

const Container = styled.div`
  width: 760px;
  margin: 70px auto 120px auto;

  @media only screen and (max-width: 1020px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Category = styled.div`
  margin: 0 0 0.2em;
  color: #ff1a75;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

const Title = styled.div`
  display: block;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  overflow: hidden;
  margin-bottom: 20px;
  color: #15171a;
  @media only screen and (max-width: 800px) {
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 22px;
    line-height: 30px;
  }
`

const Excerpt = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 28px;
  color: #60757e;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 32px 0;

  .avatar-container {
    display: block;
    overflow: hidden;
    margin: 0 0 0 -6px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
    .avatar {
      display: block;
      width: 100%;
      height: 100%;
      background: #e4eaed;
      border-radius: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .details {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-name {
    color: #373c44;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

const Date = styled.span`
  color: #90a2aa;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
`

const ImageWrapper = styled.div`
  max-width: 100%;
  margin: 30px 0;

  img {
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  h1,
  h2,
  h3 {
    margin-bottom: 0.5em;
    font-weight: 700;
    line-height: 1.15;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: 3em;
    font-weight: 700;
    line-height: 1.35;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 1.2em 0 0.4em 0;
    font-weight: 700;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0 16px 0;
    font-weight: 700;
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 18px;
  }

  p {
    margin-top: 1.5rem;
    margin-bottom: 0;
    line-height: 1.75rem;
    font-size: 18px;
    color: rgb(85, 85, 85);
  }

  li p {
    margin-top: 0;
    margin-bottom: 0;
  }

  pre,
  code {
    background: #f9f9f9;
    font-size: 16px;
  }

  code {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }

  pre {
    white-space: pre;
    overflow: auto;
    padding: 1.5em;
    margin: 1.5em 0;
    border-radius: 3px;
    -webkit-overflow-scrolling: touch;
    direction: ltr;
    text-align: left;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;

    code {
      padding: 0;
    }
  }

  ul,
  ol {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 18px;
  }

  ul {
    list-style-type: disc;
  }

  li {
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

  blockquote {
    background: #f9f9f9;
    border-radius: 3px;
    padding: 1.25em;
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration: underline;
    color: inherit;
    transition: all 0.2s linear;
  }

  a:hover {
    color: #000;
    transition: all 0.2s linear;
  }
`
