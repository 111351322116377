import { useLink } from '@/utils/hook'
import { AnchorHTMLAttributes } from 'react'

export const Link = ({
  href,
  children,
  ...restProps
}: AnchorHTMLAttributes<any>): JSX.Element => {
  const link = useLink(href)

  return (
    <a href={link} {...restProps}>
      {children}
    </a>
  )
}
