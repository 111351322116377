import { useTranslation } from '@/utils/hook'
import Link from 'next/link'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { IconMenu, IconX } from '@tabler/icons-react'
import Image from 'next/image'

export function Header() {
  const t = useTranslation()

  return (
    <Popover className="sticky top-0 z-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-0">
        <div className="flex justify-between items-center py-6 md:justify-start">
          <div className="flex items-center space-x-10">
            <Link href="/">
              <span className="sr-only">HeyForm</span>
              <Image
                className="h-8 w-auto sm:h-10 mr-8"
                src="/static/logo.svg"
                alt="HeyForm Logo"
                width={150}
                height={40}
              />
            </Link>

            <Popover.Group as="nav" className="hidden md:flex space-x-8">
              <Link
                href="/product"
                className="text-lg font-medium text-slate-700 hover:text-slate-900"
              >
                {t('common.product')}
              </Link>
              <Link
                href="/templates"
                className="text-lg font-medium text-slate-700 hover:text-slate-900"
              >
                {t('common.templates')}
              </Link>
              <Link
                href="/integrations"
                className="text-lg font-medium text-slate-700 hover:text-slate-900"
              >
                {t('common.integrations')}
              </Link>
              <Link
                href="/pricing"
                className="text-lg font-medium text-slate-700 hover:text-slate-900"
              >
                {t('common.pricing')}
              </Link>
              <Link
                href="/blog"
                className="text-lg font-medium text-slate-700 hover:text-slate-900"
              >
                {t('common.blog')}
              </Link>
            </Popover.Group>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-slate-400 hover:text-slate-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600">
              <span className="sr-only">Open menu</span>
              <IconMenu className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <Link
              href="https://my.heyform.net"
              className="whitespace-nowrap text-base font-medium text-slate-700 hover:text-slate-900"
            >
              {t('common.login')}
            </Link>
            <Link
              href="https://my.heyform.net/sign-up"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-slate-950"
            >
              {t('common.signup')}
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-[100]"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Image
                    className="h-8 w-auto sm:h-10"
                    src="/static/logo.svg"
                    alt="HeyForm Logo"
                    width={150}
                    height={40}
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-slate-400 hover:text-slate-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600">
                    <span className="sr-only">Close menu</span>
                    <IconX className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6 bg-white">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  href="/templates"
                  className="text-base font-medium text-slate-900 hover:text-slate-700"
                >
                  {t('common.templates')}
                </Link>
                <Link
                  href="/integrations"
                  className="text-base font-medium text-slate-900 hover:text-slate-700"
                >
                  {t('common.integrations')}
                </Link>
                <Link
                  href="/pricing"
                  className="text-base font-medium text-slate-900 hover:text-slate-700"
                >
                  {t('common.pricing')}
                </Link>
                <Link
                  href="/blog"
                  className="text-base font-medium text-slate-900 hover:text-slate-700"
                >
                  {t('common.blog')}
                </Link>
              </div>
              <div>
                <Link
                  href="https://my.heyform.net/sign-up"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-slate-950 hover:bg-blue-600"
                >
                  {t('common.signup')}
                </Link>
                <p className="mt-6 text-center text-base font-medium text-slate-500">
                  Existing customer?{' '}
                  <Link
                    href="https://my.heyform.net"
                    className="text-slate-950 underline"
                  >
                    {t('common.login')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
