import clsx from 'clsx'
import type { FC } from 'react'
import styled from 'styled-components'
import type { ComponentProps } from './types'

export type JustifyValues =
  | 'center'
  | 'space-around'
  | 'space-between'
  | 'flex-start'
  | 'flex-end'

export type AlignValues =
  | 'stretch'
  | 'center'
  | 'baseline'
  | 'flex-start'
  | 'flex-end'

export type WrapValues = 'wrap' | 'nowrap' | 'wrap-reverse' | 'revert' | 'unset'

export interface FlexProps extends ComponentProps {
  inline?: boolean
  column?: boolean
  shrink?: boolean
  align?: AlignValues
  justify?: JustifyValues
  wrap?: WrapValues
  auto?: boolean
  onClick?: (...args: any[]) => void
}

export const Flex: FC<FlexProps> = props => {
  const { children, className, ...restProps } = props
  return (
    <Container className={clsx('hey-flex', className)} {...restProps}>
      {children}
    </Container>
  )
}

const Container = styled.div<FlexProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex: ${({ auto }) => (auto ? '1 1 auto' : 'initial')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: ${({ align }) => align};
  flex-shrink: ${({ shrink }) => (shrink ? 1 : 'initial')};
  min-height: 0;
  min-width: 0;
`
