import locales from '@/locales'
import { helper } from '@heyform-inc/utils'
import { useRouter } from 'next/router'
import objectPath from 'object-path'

export function useLocale() {
  const { locale, locales, defaultLocale } = useRouter()
  return locales.includes(locale) ? locale : defaultLocale
}

export function useTranslation() {
  return (token: string, replacements?: Record<string, any>) => {
    const locale = useLocale()
    let translation = objectPath.get(locales[locale], token)

    if (helper.isValid(translation)) {
      // Replace placeholders
      if (helper.isValid(replacements) && helper.isPlainObject(replacements)) {
        Object.keys(replacements).forEach(key => {
          const regex = new RegExp(`{${key}}`, 'g')
          translation = translation.replace(regex, replacements[key])
        })
      }

      return translation
    }

    return token
  }
}

export function useLink(href: string) {
  const locale = useLocale()
  const { defaultLocale } = useRouter()

  return urlBuilder(href, locale, defaultLocale)
}

export function urlBuilder(
  href: string,
  locale: string,
  defaultLocale: string
) {
  if (!/^\//.test(href)) {
    href = '/' + href
  }

  if (locale.toLowerCase() !== defaultLocale.toLowerCase()) {
    href = '/' + locale + href
  }

  // Remove trailing slash
  href = href.replace(/\/$/, '')

  return process.env.NEXT_PUBLIC_WEBSITE_URI.replace(/\/$/, '') + href
}
