import { Dropdown, Tooltip } from '@/components/ui'
import {
  IconBrandTwitter,
  IconHelp,
  IconMail,
  IconMessageDots
} from '@tabler/icons-react'
import clsx from 'clsx'
import { FC } from 'react'

export const HelpFloatButton: FC<any> = ({ className, ...restProps }) => {
  const options = [
    {
      icon: <IconHelp className="h-5 w-5" />,
      label: 'Help center',
      value: 'https://docs.heyform.net/?ref=floatbutton'
    },
    {
      icon: <IconMessageDots className="h-5 w-5" />,
      label: 'Join our community',
      value: 'https://discord.gg/j9mESjjG?ref=floatbutton'
    },
    {
      icon: <IconMail className="h-5 w-5" />,
      label: 'Send us an email',
      value: 'mailto:support@heyform.net'
    },
    {
      icon: <IconBrandTwitter className="h-5 w-5" />,
      label: 'Follow us on Twitter',
      value: 'https://twitter.com/HeyformHQ?ref=floatbutton'
    }
  ]

  function handleClick(url: string) {
    window.open(url)
  }

  return (
    <Tooltip label="Help">
      <div className={clsx('fixed bottom-8 right-8 z-20', className)}>
        <Dropdown options={options} onClick={handleClick} {...restProps}>
          <button
            className="p-1.5 md:p-1 rounded-full bg-white hover:bg-slate-100 hover:text-slate-900 shadow-[rgba(0,0,0,0.08)_0px_2px_4px,rgba(0,0,0,0.06)_0px_2px_12px,rgba(0,0,0,0.04)_0px_8px_14px,rgba(0,0,0,0.02)_0px_12px_16px]"
            role="button"
            aria-label="Help Button"
          >
            <IconHelp className="w-6 h-6 text-slate-600" />
          </button>
        </Dropdown>
      </div>
    </Tooltip>
  )
}
