import { InternalServerError } from '@/components'
import '@/styles/globals.css'
import { AppProps } from 'next/app'
import { Suspense, useEffect } from 'react'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import { Hind_Siliguri } from 'next/font/google'
import cookies from 'js-cookie'
import { UTM_SOURCE_COOKIE_NAME } from '@/constants/cookie'

const hindSiliguri = Hind_Siliguri({
  subsets: ['latin'],
  weight: ['400', '500', '700']
}) // 使用 Hind Siliguri 字体

export default function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  useEffect(() => {
    const { searchParams } = new URL(window.location.href.toLowerCase())

    if (searchParams.get('utm_source')) {
      cookies.set(UTM_SOURCE_COOKIE_NAME, searchParams.get('utm_source'), {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        sameSite: 'lax'
      })
    }
  }, [])

  return (
    <Suspense fallback={<InternalServerError />}>
      <div className={hindSiliguri.className}>
        {/* 使用 Hind Siliguri 字体 */}
        <Component {...pageProps} />
      </div>
      <ProgressBar
        height="2px"
        color="#1d4ed8"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </Suspense>
  )
}
