import Link from 'next/link'

const CONTACT_US_FORM_URL = `${process.env.NEXT_PUBLIC_HOMEPAGE_URI}/f/${process.env.NEXT_PUBLIC_CONTACT_FORM_ID}`

export function NotFoundError(): JSX.Element {
  return (
    <div className="flex py-48">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 justify-center text-center">
        <h1 className="text-5xl font-bold mb-4">UH OH! You are lost.</h1>
        <p className="text-2xl text-slate-500">
          The page you are looking for no longer exist.
        </p>
        <div className="mt-12">
          <Link
            className="px-6 py-3 bg-slate-950 text-white rounded-lg"
            href="/"
          >
            Back to homepage
          </Link>
        </div>
        <div className="text-sm mt-8">
          Having trouble?{' '}
          <Link className="underline" href={CONTACT_US_FORM_URL}>
            Send us a message
          </Link>
        </div>
      </div>
    </div>
  )
}

export function InternalServerError(): JSX.Element {
  return (
    <div className="flex py-48">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 justify-center text-center">
        <h1 className="text-5xl font-bold mb-4">Oops, something went wrong</h1>
        <p className="text-2xl text-slate-500">
          An server error 500 occurred. Apologize for the inconvenience, and we
          are fixing the problem actively. Please come back later.
        </p>
        <div className="mt-12">
          <Link
            className="px-6 py-3 bg-slate-950 text-white rounded-lg"
            href="/"
          >
            Back to homepage
          </Link>
        </div>
        <div className="text-sm mt-8">
          Having trouble?{' '}
          <Link className="underline" href={CONTACT_US_FORM_URL}>
            Send us a message
          </Link>
        </div>
      </div>
    </div>
  )
}
