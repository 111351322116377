import { helper, nanoid } from '@heyform-inc/utils'
import type { CookieAttributes } from 'js-cookie'
import cookies from 'js-cookie'

export const DEVICE_ID_COOKIE_NAME = 'HEYFORM_USER_ID'
export const LOGGED_IN_COOKIE_NAME = 'HEYFORM_LOGGED_IN'

const COOKIE_OPTIONS: CookieAttributes = {
  expires: 365,
  sameSite: 'strict',
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  secure: process.env.NODE_ENV === 'production'
}

export function setCookie(
  key: string,
  value: string,
  options?: CookieAttributes
) {
  cookies.set(key, value, {
    ...{},
    ...COOKIE_OPTIONS,
    ...options
  })
}

export function getCookie(key: string) {
  return cookies.get(key)
}

export function getDeviceId() {
  let cache = getCookie(DEVICE_ID_COOKIE_NAME)

  if (helper.isEmpty(cache)) {
    cache = nanoid(12)
    setCookie(DEVICE_ID_COOKIE_NAME, cache)
  }

  return cache
}

export function setDeviceId(deviceId: string) {
  setCookie(DEVICE_ID_COOKIE_NAME, deviceId)
}
