import { useTranslation } from '@/utils/hook'
import Link from 'next/link'
import Image from 'next/image'
import {
  RiFacebookCircleFill,
  RiTwitterXFill,
  RiLinkedinFill,
  RiGithubFill
} from '@remixicon/react'

const navigation = {
  product: [
    { name: 'Features', href: '/product' },
    { name: 'Templates', href: '/templates' },
    { name: 'Integrations', href: '/integrations' },
    { name: 'Pricing', href: '/pricing' },
    { name: "What's new ✨", href: 'https://docs.heyform.net/changelog' },
    { name: 'Request a feature', href: 'https://form.canny.io/requests' }
  ],
  resources: [
    { name: 'Help Center', href: 'https://docs.heyform.net' },
    { name: 'Open Source', href: 'https://github.com/heyform/heyform' },
    { name: 'Community', href: 'https://discord.gg/sgT4v4GSTe' },
    { name: 'Blog', href: '/blog' }
  ],
  company: [
    { name: 'About', href: '/about-us' },
    { name: 'Contact Us', href: '/contact' },
    { name: 'Partners', href: '/partners' },
    { name: 'Climate Pledge', href: 'https://climate.stripe.com/Vxe5z7' }
  ],
  legal: [
    {
      name: 'Privacy Privacy',
      href: 'https://docs.heyform.net/legal/privacy'
    },
    {
      name: 'Terms of Service',
      href: 'https://docs.heyform.net/legal/tos'
    },
    {
      name: 'Report Abuse',
      href: 'https://docs.heyform.net/legal/report-abuse'
    }
  ],
  compare: [
    {
      name: 'Typeform Alternative',
      href: 'https://heyform.net/blog/vs-typeform'
    },
    {
      name: 'Google Forms Alternative',
      href: 'https://heyform.net/blog/vs-google-forms'
    }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/HeyformHQ/',
      icon: props => <RiFacebookCircleFill {...props} />
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/HeyformHQ',
      icon: props => <RiTwitterXFill {...props} />
    },

    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/heyform/',
      icon: props => <RiLinkedinFill {...props} />
    },

    {
      name: 'Github',
      href: 'https://github.com/heyform/heyform',
      icon: props => <RiGithubFill {...props} />
    }
  ]
}

export function Footer() {
  const t = useTranslation()
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="space-y-6 xl:col-span-1">
            <Image
              className="h-10"
              src="/static/logo.svg"
              alt="HeyForm"
              width={149}
              height={40}
            />
            <p className="text-slate-900 text-base">
              {t('home.bannerHeadline')}
            </p>
            <div>
              <a
                href="https://www.producthunt.com/products/heyform"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/static/weekly1.svg"
                  alt="heyform"
                  width={250}
                  height={55}
                  className="w-[250px] h-[55px]"
                />
              </a>
            </div>
            <div>
              <a
                href="https://trendshift.io/repositories/9025"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/static/tod.svg"
                  alt="heyform"
                  width={250}
                  height={55}
                  className="w-[250px] h-[55px]"
                />
              </a>
            </div>
            <div>
              <a
                href="https://trendshift.io/repositories/9025"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://hackernews-badge.vercel.app/api?id=39895960"
                  alt="heyform"
                  width={250}
                  height={55}
                  className="w-[250px] h-[55px]"
                />
              </a>
            </div>
          </div>
          <div className="xl:place-self-end mt-12 grid md:grid-cols-4 gap-8 xl:mt-0 xl:col-span-4">
            <div className="md:grid gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Product
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.product.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Resources
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.resources.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.company.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase mt-12">
                  Legal
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.legal.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-slate-900 tracking-wider uppercase">
                  Compare
                </h3>
                <ul className="mt-4 space-y-2">
                  {navigation.compare.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-slate-800 hover:underline"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center max-w-7xl mt-16 flex max-w-7xl flex-col items-center justify-between justify-between md:flex-row">
          <div className="mt-16 mb-8 flex flex-col items-center gap-4 text-center md:flex-row md:text-left">
            <div className="flex items-center">
              <div className="relative rounded-full border-2 border-white dark:border-[#111]">
                <Image
                  src="/static/team-1.jpg"
                  alt="Avatar for Luo"
                  width={32}
                  height={32}
                  className="rounded-full"
                />
              </div>
              <div className="relative -ml-2.5 rounded-full border-2 border-white dark:border-[#111]">
                <Image
                  src="/static/team-3.jpg"
                  alt="Avatar for Wu Qin"
                  width={32}
                  height={32}
                  className="rounded-full"
                />
              </div>
            </div>
            <p className="max-w-md">
              <b className="font-semibold">
                Made by{' '}
                <Link
                  className="underline text-slate-900"
                  href="https://earlybird.im"
                >
                  EarlyBird
                </Link>
              </b>
              , a bootstrapped SaaS company. <br />
              brought to you by{' '}
              <a
                href="https://x.com/LuoBaishun"
                className="font-medium underline text-slate-900"
              >
                Luo Baishun
              </a>{' '}
              &amp;{' '}
              <a
                href="https://x.com/mufeng_me"
                className="font-medium underline text-slate-900"
              >
                Wu Qin
              </a>
              .
            </p>
          </div>

          <div className="flex flex-wrap flex-nowrap justify-center gap-1 md:justify-start md:gap-2">
            <div className="flex space-x-5">
              {navigation.social.map(item => (
                <a key={item.name} href={item.href} className="text-slate-900">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
